<script  setup lang="ts">
import type { Reply } from '@/types/api.js'
import type { GraphicPlace, RevenueResponse, Sale, SaleResponse } from '@/types/graphics.js'
import { formatDate } from '@/utils/extensions/date.js'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import moment from 'moment'

definePageMeta({
  middleware: 'auth',
})

const nuxtApp = useNuxtApp()
const globalStore = useGlobal()
const store = useAppStore()

const today = ref(formatDate(new Date()))
const monthRef = ref(new Date().getMonth() + 1)
const todaySend = ref<string[]>([])
const previousDateRange = ref<string[]>([])
const salesRevenue = ref(0)
const totalSales = ref(0)
const averagePrice = ref(0)
const salesData = ref<Sale[]>([])
const salesByCategorySeries = ref([0, 0, 0])
const allSalesAreZero = ref(false)

const totalOrdersSeries = ref<{ name: string, data: number[] }>({
  name: 'Total de ventas',
  data: [0, 0, 0],
})

const revenueSeries = ref([
  {
    name: '2024',
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
  {
    name: '2023',
    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
])

const statData = ref([
  {
    title: 'Número de ventas',
    value: '0',
    subvalue: '0',
  },
  {
    title: 'Ingresos por ventas',
    value: '0',
    subvalue: '0',
  },
  {
    title: 'Precio promedio',
    value: '0',
    subvalue: '0',
  },
])

async function loadRevenueTotal() {
  try {
    store.toggleMainLoaderManual(true)
    const res = await nuxtApp.$urlEfici.post<Reply<RevenueResponse>>(`${nuxtApp.$endpoint.dashboard.sales.url}?status=${5}&?time=${2}`, {
      dateRange: todaySend.value,
      previousDateRange: previousDateRange.value,
    })

    const revenue = res.data.data
    const totalRevenue = revenue.ThisPeriod.SalesRevenue.split(',').map(Number)
    const numberOfSales = revenue.percentFromPreviousPeriod.NumberOfSales
    const salesRevenuePrevious = revenue.percentFromPreviousPeriod.SalesRevenue
    const averagePricePrevious = revenue.percentFromPreviousPeriod.AveragePrice

    totalOrdersSeries.value.data = totalRevenue
    salesRevenue.value = Number.parseInt(revenue.ThisPeriod.AveragePrice)
    totalSales.value = Number.parseInt(revenue.ThisPeriod.NumberOfSales)
    averagePrice.value = Number.parseInt(revenue.ThisPeriod.AveragePrice)

    // Total Sales
    statData.value.at(0)!.value = revenue.ThisPeriod.NumberOfSales
    statData.value.at(0)!.subvalue = numberOfSales.includes('-') ? '0' : numberOfSales

    statData.value.at(1)!.value = revenue.ThisPeriod.SalesRevenue
    statData.value.at(1)!.subvalue = salesRevenuePrevious.includes('-') ? '0' : salesRevenuePrevious

    // Average Price
    statData.value.at(2)!.value = revenue.ThisPeriod.AveragePrice
    statData.value.at(2)!.subvalue = averagePricePrevious.includes('-') ? '0' : averagePricePrevious
  }
  catch (e) {
    const err = e as Error

    return err
  }
  finally {
    store.toggleMainLoaderManual(false)
  }
}

async function loadRevenueAnalysis() {
  try {
    const res = await nuxtApp.$urlEfici.post<Reply<{ graphic: GraphicPlace }>>(`${nuxtApp.$endpoint.dashboard.RevenueAnalytics.url}?status=${5}`)

    revenueSeries.value.at(0)!.data = res.data.data.graphic.SalesThisYearSell
    revenueSeries.value.at(1)!.data = res.data.data.graphic.SalesPreviousYearSell
  }
  catch (e) {
    return e
  }
}

async function loadSalesPerMonth(month?: number) {
  try {
    store.toggleMainLoaderManual(true)
    const res = await nuxtApp.$urlEfici.post<Reply<SaleResponse>>(
      `${nuxtApp.$endpoint.dashboard.SalesAnalytics.url}?month=${month || monthRef.value}`,
    )

    const sales = res.data.data

    salesData.value = [
      sales.productA,
      sales.productB,
      sales.productC,
    ]

    salesByCategorySeries.value = [
      sales.productA.sales,
      sales.productB.sales,
      sales.productC.sales,
    ]

    allSalesAreZero.value = salesByCategorySeries.value.every(sale => sale === 0)
  }
  catch (e) {
    const err = e as Error

    globalStore.alertState(true)
    globalStore.alertDataState(err.cause as string, err.message, 'error')
    return e
  }
  finally {
    store.toggleMainLoaderManual(false)
  }
}

function getPreviousRank() {
  previousDateRange.value = []
  todaySend.value = []

  const dates = today.value.split('a')

  const initDate = moment(new Date(dates.at(0)!))
  const endDate = moment(new Date(dates.at(1)!))

  const duracion = endDate.diff(initDate, 'days')

  const newEnd = initDate.clone().subtract(1, 'days')
  const newInit = newEnd.clone().subtract(duracion, 'days')

  previousDateRange.value.push(newInit.format('DD/MM/YYYY'))
  previousDateRange.value.push(newEnd.format('DD/MM/YYYY'))

  todaySend.value.push(initDate.format('DD/MM/YYYY'))
  todaySend.value.push(endDate.format('DD/MM/YYYY'))

  loadRevenueTotal()
}

async function handleLoadSalesPerMonth(month: string) {
  await loadSalesPerMonth(Number.parseInt(month))
}

watch(today, (state) => {
  const datesSplitted = state.split('a')

  if (datesSplitted.length > 1) {
    getPreviousRank()
  }
}, { immediate: true })

onMounted(() => {
  loadRevenueTotal()
  loadSalesPerMonth()
  loadRevenueAnalysis()

  nextTick(() => {
    window.dispatchEvent(new Event('resize'))
  })
})
</script>

<template>
  <NuxtLayout>
    <TabGroup as="div" class="mb-5">
      <TabList class="flex flex-wrap justify-between space-x-2 rtl:space-x-reverse text-center">
        <Tab v-slot="{ selected }" as="template">
          <UtilitiesSelectedTab :selected="selected">
            Analisis de ingresos
          </UtilitiesSelectedTab>
        </Tab>
        <Tab v-slot="{ selected }" as="template">
          <UtilitiesSelectedTab :selected="selected">
            Analisis de ventas
          </UtilitiesSelectedTab>
        </Tab>
      </TabList>
      <TabPanels class="pt-5">
        <TabPanel>
          <GraphicsFilterChart
            v-model="today"
            :revenue-series="revenueSeries"
            :sales-by-category-series="salesByCategorySeries"
          />
          <div id="chart">
            <GraphicsFilterStatChart :previous-date-range="previousDateRange" :stat-data="statData" />
            <GraphicsRevenue
              :sales-revenue="salesRevenue"
              :revenue-series="revenueSeries"
            />
          </div>
        </TabPanel>
        <TabPanel class="pt-5">
          <GraphicsSales
            :month-ref="monthRef"
            :sales-data="salesData"
            :sales-by-category-series="salesByCategorySeries"
            :all-sales-are-zero="allSalesAreZero"
            @handle-load-sales-per-month="handleLoadSalesPerMonth"
          />
          <GraphicsTableSalesProduct :sales-data="salesData" />
        </TabPanel>
      </TabPanels>
    </TabGroup>
  </NuxtLayout>
</template>
